export const DetailIconRegular = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#6c7280" width="24" height="24" viewBox="0 0 24 24">
    <path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h16l.002 14H4z" />
    <path d="M6 7h12v2H6zm0 4h12v2H6zm0 4h6v2H6z" />
  </svg>
);
export const DetailIconSolid = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#111827" width="24" height="24" viewBox="0 0 24 24">
    <path d="M20 3H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-9 14H5v-2h6v2zm8-4H5v-2h14v2zm0-4H5V7h14v2z" />
  </svg>
);
export const GroupIconRegular = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#6c7280" width="24" height="24" viewBox="0 0 24 24">
    <path d="M16.604 11.048a5.67 5.67 0 0 0 .751-3.44c-.179-1.784-1.175-3.361-2.803-4.44l-1.105 1.666c1.119.742 1.8 1.799 1.918 2.974a3.693 3.693 0 0 1-1.072 2.986l-1.192 1.192 1.618.475C18.951 13.701 19 17.957 19 18h2c0-1.789-.956-5.285-4.396-6.952z" />
    <path d="M9.5 12c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm1.5 7H8c-3.309 0-6 2.691-6 6v1h2v-1c0-2.206 1.794-4 4-4h3c2.206 0 4 1.794 4 4v1h2v-1c0-3.309-2.691-6-6-6z" />
  </svg>
);
export const GroupIconSolid = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#111827" width="24" height="24" viewBox="0 0 24 24">
    <path d="M9.5 12c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm1.5 1H8c-3.309 0-6 2.691-6 6v1h15v-1c0-3.309-2.691-6-6-6z" />
    <path d="M16.604 11.048a5.67 5.67 0 0 0 .751-3.44c-.179-1.784-1.175-3.361-2.803-4.44l-1.105 1.666c1.119.742 1.8 1.799 1.918 2.974a3.693 3.693 0 0 1-1.072 2.986l-1.192 1.192 1.618.475C18.951 13.701 19 17.957 19 18h2c0-1.789-.956-5.285-4.396-6.952z" />
  </svg>
);
export const DollarCircleIconRegular = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#6c7280" width="24" height="24" viewBox="0 0 24 24">
    <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
    <path d="M12 11c-2 0-2-.63-2-1s.7-1 2-1 1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3 2 0 2 .68 2 1s-.62 1-2 1c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92 0-1.12-.52-3-4-3z" />
  </svg>
);
export const DollarCircleIconSolid = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#111827" width="24" height="24" viewBox="0 0 24 24">
    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm1 14.915V18h-2v-1.08c-2.339-.367-3-2.002-3-2.92h2c.011.143.159 1 2 1 1.38 0 2-.585 2-1 0-.324 0-1-2-1-3.48 0-4-1.88-4-3 0-1.288 1.029-2.584 3-2.915V6.012h2v1.109c1.734.41 2.4 1.853 2.4 2.879h-1l-1 .018C13.386 9.638 13.185 9 12 9c-1.299 0-2 .516-2 1 0 .374 0 1 2 1 3.48 0 4 1.88 4 3 0 1.288-1.029 2.584-3 2.915z" />
  </svg>
);
export const BellIconSolid = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#111827" width="24" height="24" viewBox="0 0 24 24">
    <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" />
  </svg>
);
export const BellIconRegular = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#6c7280" width="24" height="24" viewBox="0 0 24 24">
    <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" />
  </svg>
);
export const UserIconRegular = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#6c7280" width="24" height="24" viewBox="0 0 24 24">
    <path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z" />
  </svg>
);
export const UserIconSolid = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#111827" width="24" height="24" viewBox="0 0 24 24">
    <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z" />
  </svg>
);
export const WorldIconRegular = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#6c7280" width="16" height="16" viewBox="0 0 24 24">
    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zM4 12c0-.899.156-1.762.431-2.569L6 11l2 2v2l2 2 1 1v1.931C7.061 19.436 4 16.072 4 12zm14.33 4.873C17.677 16.347 16.687 16 16 16v-1a2 2 0 0 0-2-2h-4v-3a2 2 0 0 0 2-2V7h1a2 2 0 0 0 2-2v-.411C17.928 5.778 20 8.65 20 12a7.947 7.947 0 0 1-1.67 4.873z" />
  </svg>
);
export const TimeIconRegular = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#6c7280" width="16" height="16" viewBox="0 0 24 24">
    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" />
    <path d="M13 7h-2v6h6v-2h-4z" />
  </svg>
);
export const LogInIconRegular = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#6c7280" width="24" height="24" viewBox="0 0 24 24">
    <path d="m13 16 5-4-5-4v3H4v2h9z" />
    <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z" />
  </svg>
);
export const LogInIconSolid = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#111827" width="24" height="24" viewBox="0 0 24 24">
    <path d="M18.5 2h-13a.5.5 0 0 0-.5.5V11h6V8l5 4-5 4v-3H5v8.5a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-19a.5.5 0 0 0-.5-.5z" />
  </svg>
);
export const PlusIconRegular = (): JSX.Element => (
  <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 5.75V8M8 8V10.25M8 8H10.25M8 8H5.75M14.75 8C14.75 8.88642 14.5754 9.76417 14.2362 10.5831C13.897 11.4021 13.3998 12.1462 12.773 12.773C12.1462 13.3998 11.4021 13.897 10.5831 14.2362C9.76417 14.5754 8.88642 14.75 8 14.75C7.11358 14.75 6.23583 14.5754 5.41689 14.2362C4.59794 13.897 3.85382 13.3998 3.22703 12.773C2.60023 12.1462 2.10303 11.4021 1.76381 10.5831C1.42459 9.76417 1.25 8.88642 1.25 8C1.25 6.20979 1.96116 4.4929 3.22703 3.22703C4.4929 1.96116 6.20979 1.25 8 1.25C9.79021 1.25 11.5071 1.96116 12.773 3.22703C14.0388 4.4929 14.75 6.20979 14.75 8Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronLeftIconRegular = (): JSX.Element => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99967 10.6668L1.33301 6.00016L5.99967 1.3335"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MapIconRegular = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.657 16.6572L13.414 20.9002C13.2284 21.0859 13.0081 21.2333 12.7656 21.3338C12.523 21.4344 12.2631 21.4861 12.0005 21.4861C11.738 21.4861 11.478 21.4344 11.2354 21.3338C10.9929 21.2333 10.7726 21.0859 10.587 20.9002L6.343 16.6572C5.22422 15.5384 4.46234 14.1129 4.15369 12.5611C3.84504 11.0092 4.00349 9.40071 4.60901 7.93893C5.21452 6.47714 6.2399 5.22774 7.55548 4.3487C8.87107 3.46967 10.4178 3.00049 12 3.00049C13.5822 3.00049 15.1289 3.46967 16.4445 4.3487C17.7601 5.22774 18.7855 6.47714 19.391 7.93893C19.9965 9.40071 20.155 11.0092 19.8463 12.5611C19.5377 14.1129 18.7758 15.5384 17.657 16.6572V16.6572Z"
      stroke="#374151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1213 13.1213C14.6839 12.5587 15 11.7956 15 11C15 10.2044 14.6839 9.44129 14.1213 8.87868C13.5587 8.31607 12.7956 8 12 8C11.2044 8 10.4413 8.31607 9.87868 8.87868C9.31607 9.44129 9 10.2044 9 11C9 11.7956 9.31607 12.5587 9.87868 13.1213C10.4413 13.6839 11.2044 14 12 14C12.7956 14 13.5587 13.6839 14.1213 13.1213Z"
      stroke="#374151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIconRegular = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 16.5L11.5 11.5M13.1667 7.33333C13.1667 8.09938 13.0158 8.85792 12.7226 9.56565C12.4295 10.2734 11.9998 10.9164 11.4581 11.4581C10.9164 11.9998 10.2734 12.4295 9.56565 12.7226C8.85792 13.0158 8.09938 13.1667 7.33333 13.1667C6.56729 13.1667 5.80875 13.0158 5.10101 12.7226C4.39328 12.4295 3.75022 11.9998 3.20854 11.4581C2.66687 10.9164 2.23719 10.2734 1.94404 9.56565C1.65088 8.85792 1.5 8.09938 1.5 7.33333C1.5 5.78624 2.11458 4.30251 3.20854 3.20854C4.30251 2.11458 5.78624 1.5 7.33333 1.5C8.88043 1.5 10.3642 2.11458 11.4581 3.20854C12.5521 4.30251 13.1667 5.78624 13.1667 7.33333Z"
      stroke="#374151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RightArrowIconRegular = (): JSX.Element => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.33333 1.3335L13 6.00016M13 6.00016L8.33333 10.6668M13 6.00016H1"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RightArrowIconRegularWhite = (): JSX.Element => (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.46975 9.96973L5.53025 11.0302L10.5605 5.99998L5.53025 0.969727L4.46975 2.03023L7.6895 5.24998H0.5V6.74998H7.6895L4.46975 9.96973Z"
      fill="white"
    />
  </svg>
);

export const PlusCircleIconRegular = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 7.5V10M10 10V12.5M10 10H12.5M10 10H7.5M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CalendarIconRegular = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66667 5.83333V2.5M13.3333 5.83333V2.5M5.83333 9.16667H14.1667M4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V5.83333C17.5 5.39131 17.3244 4.96738 17.0118 4.65482C16.6993 4.34226 16.2754 4.16667 15.8333 4.16667H4.16667C3.72464 4.16667 3.30072 4.34226 2.98816 4.65482C2.67559 4.96738 2.5 5.39131 2.5 5.83333V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5Z"
      stroke="#374151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronLeftIconBold = (): JSX.Element => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M7.24853 0.351832C7.4735 0.576865 7.59988 0.882035 7.59988 1.20023C7.59988 1.51843 7.4735 1.8236 7.24853 2.04863L3.29693 6.00023L7.24853 9.95183C7.46712 10.1782 7.58808 10.4813 7.58534 10.7959C7.58261 11.1105 7.45641 11.4115 7.23392 11.634C7.01143 11.8565 6.71045 11.9827 6.39582 11.9854C6.08118 11.9882 5.77806 11.8672 5.55173 11.6486L0.751735 6.84863C0.526769 6.6236 0.400391 6.31843 0.400391 6.00023C0.400391 5.68203 0.526769 5.37686 0.751735 5.15183L5.55173 0.351832C5.77677 0.126867 6.08194 0.000488281 6.40013 0.000488281C6.71833 0.000488281 7.0235 0.126867 7.24853 0.351832Z"
      fill="#111827"
    />
  </svg>
);

export const ChevronRightIconBold = (): JSX.Element => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M0.751735 11.6483C0.526769 11.4233 0.400391 11.1181 0.400391 10.7999C0.400391 10.4817 0.526769 10.1765 0.751735 9.9515L4.70333 5.9999L0.751735 2.0483C0.533145 1.82198 0.412191 1.51886 0.414925 1.20422C0.417659 0.889585 0.543862 0.58861 0.766352 0.366121C0.988842 0.143631 1.28982 0.0174279 1.60445 0.0146938C1.91909 0.0119597 2.22221 0.132913 2.44853 0.351503L7.24853 5.1515C7.4735 5.37654 7.59988 5.68171 7.59988 5.9999C7.59988 6.3181 7.4735 6.62327 7.24853 6.8483L2.44853 11.6483C2.2235 11.8733 1.91833 11.9996 1.60013 11.9996C1.28194 11.9996 0.976768 11.8733 0.751735 11.6483Z"
      fill="#111827"
    />
  </svg>
);

export const ChevronDownIconRegular = (): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99983 9.17583L7.64628 8.82227L5.01193 6.18793C5.01191 6.18791 5.01189 6.18789 5.01188 6.18787C4.95562 6.13166 4.87935 6.10009 4.79983 6.10009C4.72033 6.10009 4.64408 6.13164 4.58784 6.18782C4.5878 6.18785 4.58776 6.18789 4.58773 6.18793M7.99983 9.17583L4.58773 6.18793M7.99983 9.17583L8.35338 8.82227L10.9878 6.18787L10.9878 6.18793L10.9939 6.18168C11.0215 6.15302 11.0547 6.13017 11.0913 6.11444C11.1279 6.09872 11.1672 6.09045 11.2071 6.0901C11.2469 6.08975 11.2864 6.09734 11.3233 6.11243C11.3601 6.12751 11.3936 6.14979 11.4218 6.17796C11.45 6.20612 11.4722 6.23962 11.4873 6.27649L11.9501 6.08716L11.4873 6.27649C11.5024 6.31336 11.51 6.35286 11.5096 6.39269C11.5093 6.43253 11.501 6.47189 11.4853 6.5085C11.4696 6.5451 11.4467 6.5782 11.4181 6.60587L11.418 6.60582L11.4119 6.61196L8.21193 9.81191C8.21191 9.81193 8.21189 9.81195 8.21188 9.81196C8.15562 9.86817 8.07935 9.89975 7.99983 9.89975C7.92031 9.89975 7.84404 9.86817 7.78778 9.81196C7.78776 9.81195 7.78775 9.81193 7.78773 9.81191L4.58784 6.61202M7.99983 9.17583L4.58784 6.61202M4.58773 6.18793C4.53155 6.24417 4.5 6.32042 4.5 6.39992C4.5 6.47944 4.53157 6.55571 4.58778 6.61196M4.58773 6.18793L4.58778 6.61196M4.58778 6.61196L4.58784 6.61202M4.58778 6.61196L4.58784 6.61202"
      stroke="#D1D5DB"
    />
  </svg>
);

export const PencilIconRegular = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.33317 3.33312H3.99984C3.64622 3.33312 3.30708 3.47359 3.05703 3.72364C2.80698 3.97369 2.6665 4.31283 2.6665 4.66645V11.9998C2.6665 12.3534 2.80698 12.6925 3.05703 12.9426C3.30708 13.1926 3.64622 13.3331 3.99984 13.3331H11.3332C11.6868 13.3331 12.0259 13.1926 12.276 12.9426C12.526 12.6925 12.6665 12.3534 12.6665 11.9998V8.66645M11.7238 2.39045C11.8468 2.2631 11.994 2.16153 12.1566 2.09165C12.3193 2.02177 12.4943 1.98499 12.6713 1.98345C12.8483 1.98191 13.0239 2.01565 13.1878 2.08269C13.3516 2.14973 13.5005 2.24873 13.6257 2.37392C13.7509 2.49911 13.8499 2.64798 13.9169 2.81184C13.984 2.97571 14.0177 3.15128 14.0162 3.32832C14.0146 3.50536 13.9779 3.68032 13.908 3.84299C13.8381 4.00566 13.7365 4.15279 13.6092 4.27578L7.88517 9.99978H5.99984V8.11445L11.7238 2.39045Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditIconRegular = (): JSX.Element => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1547 3.98822L12.512 6.34555M11.1547 2.98822C11.4673 2.67562 11.8912 2.5 12.3333 2.5C12.7754 2.5 13.1994 2.67562 13.512 2.98822C13.8246 3.30082 14.0002 3.7248 14.0002 4.16689C14.0002 4.60897 13.8246 5.03295 13.512 5.34555L4.33333 14.5242H2V12.1429L11.1547 2.98822Z"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TrashIconRegular = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.6665 7.33333V11.3333M9.33317 7.33333V11.3333M2.6665 4.66667H13.3332M12.6665 4.66667L12.0885 12.7613C12.0646 13.0977 11.914 13.4125 11.6672 13.6424C11.4205 13.8722 11.0957 14 10.7585 14H5.24117C4.90393 14 4.57922 13.8722 4.33243 13.6424C4.08564 13.4125 3.93511 13.0977 3.91117 12.7613L3.33317 4.66667H12.6665ZM9.99984 4.66667V2.66667C9.99984 2.48986 9.9296 2.32029 9.80457 2.19526C9.67955 2.07024 9.50998 2 9.33317 2H6.6665C6.48969 2 6.32012 2.07024 6.1951 2.19526C6.07008 2.32029 5.99984 2.48986 5.99984 2.66667V4.66667H9.99984Z"
      stroke="#111827"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const VisaLogo = (): JSX.Element => (
  <svg width="42" height="14" viewBox="0 0 42 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6075 13.0969H7.09378L4.45895 2.98713C4.33389 2.52208 4.06836 2.11095 3.67776 1.91718C2.70297 1.43022 1.62883 1.04268 0.457031 0.847223V0.457996H6.11729C6.89848 0.457996 7.48438 1.04268 7.58203 1.72172L8.94913 9.01425L12.4611 0.457996H15.8771L10.6075 13.0969ZM17.8301 13.0969H14.5117L17.2442 0.457996H20.5626L17.8301 13.0969ZM24.8558 3.95936C24.9534 3.27863 25.5393 2.88941 26.2229 2.88941C27.297 2.79168 28.4671 2.98714 29.4436 3.47241L30.0295 0.751187C29.053 0.36196 27.9788 0.166504 27.004 0.166504C23.7833 0.166504 21.4397 1.91718 21.4397 4.3469C21.4397 6.19531 23.0998 7.16585 24.2716 7.75053C25.5393 8.33353 26.0276 8.72276 25.9299 9.30575C25.9299 10.1803 24.9534 10.5695 23.9786 10.5695C22.8068 10.5695 21.635 10.278 20.5626 9.79102L19.9767 12.5139C21.1485 12.9992 22.4162 13.1947 23.588 13.1947C27.1993 13.2907 29.4436 11.5417 29.4436 8.91653C29.4436 5.61063 24.8558 5.41685 24.8558 3.95936ZM41.057 13.0969L38.4222 0.457996H35.5921C35.0062 0.457996 34.4203 0.847223 34.225 1.43022L29.3459 13.0969H32.7619L33.4438 11.2502H37.641L38.0316 13.0969H41.057ZM36.0803 3.86164L37.0551 8.62504H34.3226L36.0803 3.86164Z"
      fill="#172B85"
    />
  </svg>
);

export const MastercardLogo = (): JSX.Element => (
  <svg width="39" height="23" viewBox="0 0 39 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.261 20.1016C17.2459 21.802 14.632 22.8286 11.7757 22.8286C5.40268 22.8286 0.236328 17.7182 0.236328 11.4143C0.236328 5.11035 5.40268 0 11.7757 0C14.632 0 17.2459 1.02653 19.261 2.72697C21.276 1.02653 23.89 0 26.7463 0C33.1193 0 38.2856 5.11035 38.2856 11.4143C38.2856 17.7182 33.1193 22.8286 26.7463 22.8286C23.89 22.8286 21.276 21.802 19.261 20.1016Z"
      fill="#ED0006"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2607 20.1016C21.7417 18.008 23.3148 14.8929 23.3148 11.4143C23.3148 7.93572 21.7417 4.8206 19.2607 2.72701C21.2758 1.02655 23.8897 0 26.7461 0C33.1191 0 38.2854 5.11035 38.2854 11.4143C38.2854 17.7182 33.1191 22.8286 26.7461 22.8286C23.8897 22.8286 21.2758 21.802 19.2607 20.1016Z"
      fill="#F9A000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2611 20.1016C21.742 18.008 23.3151 14.8929 23.3151 11.4143C23.3151 7.93577 21.742 4.82064 19.2611 2.72705C16.7801 4.82064 15.207 7.93577 15.207 11.4143C15.207 14.8929 16.7801 18.008 19.2611 20.1016Z"
      fill="#FF5E00"
    />
  </svg>
);

export const CheckIconRegular = (): JSX.Element => (
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.44238 5.75L4.44238 8.75L11.9424 1.25"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowNarrowDown = (): JSX.Element => (
  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.16732 11.2551L4.50065 13.9218M4.50065 13.9218L1.83398 11.2551M4.50065 13.9218V1.92175"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CartIconOutline = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1H3L3.4 3M3.4 3H19L15 11H5M3.4 3L5 11M5 11L2.707 13.293C2.077 13.923 2.523 15 3.414 15H15M15 15C14.4696 15 13.9609 15.2107 13.5858 15.5858C13.2107 15.9609 13 16.4696 13 17C13 17.5304 13.2107 18.0391 13.5858 18.4142C13.9609 18.7893 14.4696 19 15 19C15.5304 19 16.0391 18.7893 16.4142 18.4142C16.7893 18.0391 17 17.5304 17 17C17 16.4696 16.7893 15.9609 16.4142 15.5858C16.0391 15.2107 15.5304 15 15 15ZM7 17C7 17.5304 6.78929 18.0391 6.41421 18.4142C6.03914 18.7893 5.53043 19 5 19C4.46957 19 3.96086 18.7893 3.58579 18.4142C3.21071 18.0391 3 17.5304 3 17C3 16.4696 3.21071 15.9609 3.58579 15.5858C3.96086 15.2107 4.46957 15 5 15C5.53043 15 6.03914 15.2107 6.41421 15.5858C6.78929 15.9609 7 16.4696 7 17Z"
      stroke="#6B7280"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GreenCheckCircle = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z"
      fill="#047857"
    />
  </svg>
);

export const EyeIcon = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.06066 7.36681C7.34196 7.08551 7.5 6.70398 7.5 6.30615C7.5 5.90833 7.34196 5.5268 7.06066 5.24549C6.77936 4.96419 6.39782 4.80615 6 4.80615C5.60218 4.80615 5.22064 4.96419 4.93934 5.24549C4.65804 5.5268 4.5 5.90833 4.5 6.30615C4.5 6.70398 4.65804 7.08551 4.93934 7.36681C5.22064 7.64812 5.60218 7.80615 6 7.80615C6.39782 7.80615 6.77936 7.64812 7.06066 7.36681Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.229 6.30615C1.866 4.27765 3.7615 2.80615 6 2.80615C8.239 2.80615 10.134 4.27765 10.771 6.30615C10.134 8.33465 8.239 9.80615 6 9.80615C3.7615 9.80615 1.866 8.33465 1.229 6.30615Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditSquareIcon = (): JSX.Element => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1.95923H4C2.3455 1.95923 1 3.30473 1 4.95923V11.4592C1 11.5918 1.05268 11.719 1.14645 11.8128C1.24021 11.9066 1.36739 11.9592 1.5 11.9592H8C9.6545 11.9592 11 10.6137 11 8.95923V4.95923C11 3.30473 9.6545 1.95923 8 1.95923ZM10 8.95923C10 10.0622 9.103 10.9592 8 10.9592H2V4.95923C2 3.85623 2.897 2.95923 4 2.95923H8C9.103 2.95923 10 3.85623 10 4.95923V8.95923Z"
      fill="white"
    />
    <path
      d="M3.5 8.45273V9.45223H4.4995L7.264 6.69123L6.265 5.69223L3.5 8.45273ZM7.735 6.22023L6.736 5.22023L7.4975 4.45923L8.4975 5.45873L7.735 6.22023Z"
      fill="white"
    />
  </svg>
);

export const WarningIcon = (): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5005 5H6.5005V7.5H5.5005V5ZM5.5 8H6.5V9H5.5V8Z" fill="white" />
    <path
      d="M6.88446 2.10009C6.71046 1.77259 6.37146 1.56909 6.00046 1.56909C5.62946 1.56909 5.29046 1.77259 5.11646 2.10059L1.44746 9.03209C1.36605 9.18431 1.32572 9.35512 1.33045 9.52767C1.33519 9.70023 1.38482 9.86857 1.47446 10.0161C1.56281 10.1643 1.68828 10.2869 1.83849 10.3717C1.98869 10.4566 2.15844 10.5009 2.33096 10.5001H9.66996C10.024 10.5001 10.3445 10.3191 10.527 10.0161C10.6165 9.86852 10.666 9.70019 10.6707 9.52767C10.6755 9.35514 10.6352 9.18435 10.554 9.03209L6.88446 2.10009ZM2.33096 9.50009L6.00046 2.56859L9.67246 9.50009H2.33096Z"
      fill="white"
    />
  </svg>
);

export const ImageIcon = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 2H3.33333C2.598 2 2 2.598 2 3.33333V12.6667C2 13.402 2.598 14 3.33333 14H12.6667C13.402 14 14 13.402 14 12.6667V3.33333C14 2.598 13.402 2 12.6667 2ZM3.33333 12.6667V3.33333H12.6667L12.668 12.6667H3.33333Z"
      fill="white"
    />
    <path d="M6.66667 9.33341L6 8.66675L4 11.3334H12L8.66667 6.66675L6.66667 9.33341Z" fill="white" />
  </svg>
);

export const TextIcon = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33301 5.33341H4.66634V4.00008H6.83434L5.11967 12.0001H3.33301V13.3334H8.66634V12.0001H7.16501L8.87967 4.00008H11.333V5.33341H12.6663V2.66675H3.33301V5.33341Z"
      fill="white"
    />
  </svg>
);
